<template>
  <ul class="flex space-x-4">
    <li v-for="el in sliderLength" :key="el">
      <button
        class="
          w-2
          h-2
          rounded-full
          cursor-pointer
          focus:outline-none
          hover:bg-black-900
        "
        :class="[el - 1 === currentIndex ? 'bg-orange-900' : 'bg-grey-600']"
        @click="goToSlide(el - 1)"
      ></button>
    </li>
  </ul>
</template>

<script>
export default {
  name: "SliderPagination",
  props: {
    sliderLength: {
      type: Number,
      required: true,
    },
    currentIndex: {
      type: Number,
      default: () => 0,
    },
  },
  methods: {
    goToSlide(index) {
      this.$emit("go-to-slide", index);
    },
  },
};
</script>
